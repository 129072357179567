import "./home.css"
import PlayRoulette from "../../roulette/components/PlayRoulette";
import PlayRouletteButton from "../PlayRouletteButton";
import React, {useEffect} from "react";

export function Home() {
    useEffect(() => {
        document.title = "JustStopGambling.org"
    }, [])
    return (<>
            <div className="tagline">
                Exposing the Truth Behind Gambling Fallacies<hr/>
            </div>
            <p>Do you believe any of the following statements?</p>
            <div className="statements">
                <a href="/article/bankroll-management-is-a-myth"><div className="statement false font-light">Quiting while you are ahead is a good strategy</div></a>
                <a href="/article/roulette-betting-systems-do-not-work"><div className="statement false font-light">There are good and bad betting systems</div></a>
                <a href="/article/roulette-betting-systems-do-not-work"><div className="statement false font-light">The martingale betting system is either good or bad</div></a>
                <a href="/article/results-do-not-affect-future-results"><div className="statement false font-light">You can bet to exploit patterns</div></a>
            </div>
            <p>If you said yes to any of the above, this website is for you. You are likely being exploited by an industry.</p>
                <p>The following statements are objectively true, and this website will help you understand why.</p>
            <div className="statements">
                <a href="/article/roulette-betting-systems-do-not-work"><div className="statement true font-light">In house casino games, there are no winning strategies</div></a>
                <a href="/article/roulette-betting-systems-do-not-work"><div className="statement true font-light">There are only losing strategies</div></a>
                <a href="/article/roulette-betting-systems-do-not-work"><div className="statement true font-light">Every strategy is equally useless to win</div></a>
                <a href="/article/bankroll-management-is-a-myth"><div className="statement true font-light">The most profitable move is to never gamble again</div></a>
            </div>
            <p>Do not give the casino your money, start your journey here to taking back control of your money.</p>
            <div className="buttons">
                {/*<a className="font-light" href="learn">*/}
                {/*    <div className="button font-light">*/}
                {/*        <div className="button-header">Learn</div>*/}
                {/*        <div className="button-body">Read interactive articles on gambling fallacies, improve your*/}
                {/*            understanding*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</a>*/}
                <PlayRouletteButton/>
            </div>
        </>
    )
}