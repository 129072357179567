import {createContext, useContext, useState} from "react";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {blackNumbers, getWheelResult, redNumbers} from "./Utils";
import play = Simulate.play;

export const GameStateContext = createContext(undefined);

export enum Strategy {
    martingale,
    reverseMartingale,
flat,
}

export type Player = {
    balance: number
}

export type Table = {
    minBet: number,
    maxBet: number,
}

export type Game = {
    id: number,
    bets: Bet[],
    result: number,
}

export interface Bet {
    betType: BetType,
    betChoice: number,
    amount: number,
}

export enum BetType {
    Single,
    RedBlack,
    EvenOdd,
    Half,
}
const tableInitialState = {minBet: 1, maxBet: 40};
const playerInitialState = {balance: 0, strategy: undefined};

export const GameStateContextProvider = (props) => {
    const [games, setGames] = useState<Array<Game>>([])
    const [player, setPlayer] = useState<Player>(
        playerInitialState
    )
    const [table, setTable] = useState<Table>(
        tableInitialState
    )

    const getActualOdds = (betType: BetType) => {
        switch (betType) {
            case BetType.Single:
                return 1 / 37;
                break;
            case BetType.EvenOdd:
            case BetType.RedBlack:
            case BetType.Half:
                return 18 / 37;
                break;
            default:
                throw new Error("Enum not found");
        }
    }

    const getHouseEdge = (betType: BetType) => {
        return (1 * (1 - getActualOdds(betType)))
            - (1 * (getPayoutOdds(betType) * getActualOdds(betType)))
    }

    const getExpectedReturn = (game: Game = undefined) => {
        if (game === undefined) {
            return -1 * games.map(i => i.bets.map(b => b.amount * getHouseEdge(b.betType)).reduce((a, b) => a + b, 0))
                .reduce((a, b) => a + b, 0)
        } else {
            return -1 * game.bets.map(b => b.amount * getHouseEdge(b.betType)).reduce((a, b) => a + b, 0)
        }
    }

    const getPayoutOdds = (betType: BetType) => {
        switch (betType) {
            case BetType.Single:
                return 35;
                break;
            case BetType.EvenOdd:
            case BetType.RedBlack:
            case BetType.Half:
                return 1;
                break;
            default:
                throw new Error("Enum not found");
        }
    }
    const getDescription = (betType: BetType) => {
        switch (betType) {
            case BetType.Single:
                return "Single Number"
                break;
            default:
                throw new Error("Enum not found");
        }
    }
    const didBetWin = (game: Game, bet: Bet) => {
        switch (bet.betType) {
            case BetType.Single:
                return bet.betChoice === game.result;
                break;
            case BetType.EvenOdd:
                return game.result !== 0 && (game.result % 2 === bet.betChoice);
                break;
            case BetType.RedBlack:
                return bet.betChoice === 0 ?
                    redNumbers.includes(game.result) :
                    blackNumbers.includes(game.result)
            case BetType.Half:
                return bet.betChoice === 0 ? game.result >= 1 && game.result <= 18 :
                    game.result >= 19 && game.result <= 36;
                break;
            default:
                throw new Error("Enum not found");
        }
    }

    const getPayoutForBet = (game: Game, bet: Bet) => {
        return didBetWin(game, bet) ? ((getPayoutOdds(bet.betType) * bet.amount) + bet.amount) : 0;
    }

    const getTotalPayout = (game: Game) => {
        return game.bets.map(i => getPayoutForBet(game, i)).reduce((a, b) => a + b, 0)
    }

    const getProfitForGame = (game: Game) => {
        return -game.bets.map(i=>i.amount).reduce((a,b)=>a+b, 0) +
            game.bets.map(i => getPayoutForBet(game, i)).reduce((a, b) => a + b, 0)
    }

    const getCurrentGame = () => {
        return games[games.length - 1];
    }

    const addGame = (amount: Number = 1) => {
        const tempNewGames = [...games]
        for (let i: number = 0; i < amount; i++) {
            const game: Game = {bets: [], id: games.length + tempNewGames.length, result: undefined}
            tempNewGames.push(game);
        }
        const newGames = [...tempNewGames]
        setGames(newGames)
         console.log(newGames.length)
    }

    const addBet = (bets: Bet[]) => {
        const newGames = [...games];
        newGames.forEach(i => {
            if (i.result === undefined) {
                addBetToGame(i, bets)
            }
        })
        setGames(newGames)
    }

    const addBetToGame = (i: Game, bets: Bet[]) => {
        if (i.result === undefined && bets?.length > 0) {
            i.bets.forEach(existingBet => {
                bets.forEach(newBet => {
                    if (existingBet.betType === newBet.betType && existingBet.betChoice === newBet.betChoice) {
                        existingBet.amount += newBet.amount
                        newBet.amount = 0
                    }
                })
            })
            bets.forEach(newBet => {
                if (newBet.amount != 0) {
                    i.bets.push(newBet)
                }
            })
            i.bets.forEach(b => {
                b.amount = Math.min(b.amount, table.maxBet)
                b.amount = Math.max(b.amount, table.minBet)
            })
        }
    }

    const processGame = (strategy: Strategy) => {
        const newGames = [...games];
        console.log(newGames.length)
        newGames.forEach(i => {
            if (i.result === undefined) {
                if (strategy !== undefined) {
                    // console.log(strategy)
                    if (i.bets == undefined) {
                        i.bets = []
                    }
                    if (i.bets.length == 0) {
                        addBetToGame(i, calculateBetsForStrategy(player, strategy, games))
                    }
                }
                i.result = getWheelResult();
                const totalAmountBet = i.bets.map(i => i.amount).reduce((a, b) => a + b, 0);
                setPlayer((prevState: Player) => {
                    return {...prevState, balance: prevState.balance + getTotalPayout(i) - totalAmountBet}
                });
            }
        })
        setGames(newGames)
    }

    const calculateBetsForStrategy = (player: Player, strategy: Strategy, games: Game[]): Bet[] => {
        switch (strategy) {
            case Strategy.martingale: {
                const reversedCompletedGames = getReversedCompletedGames(games);
                const lastWinningGameIndex = reversedCompletedGames.findIndex(i => getTotalPayout(i) > 0, reversedCompletedGames.length);
                const mostRecentBets = games.slice().reverse().find(i => i.bets.length > 0)?.bets;
                const bets = []
                if (mostRecentBets?.length > 0) {
                    mostRecentBets.forEach(i => {
                        bets.push({
                            betType: i.betType,
                            betChoice: i.betChoice,
                            amount: Math.pow(2, Math.max(0, lastWinningGameIndex))
                        })
                    })
                }
                return bets
            }
            case Strategy.reverseMartingale: {
                const reversedCompletedGames = getReversedCompletedGames(games);
                const lastLosingGameIndex = reversedCompletedGames.findIndex(i => getTotalPayout(i) === 0, reversedCompletedGames.length);
                const mostRecentBets = games.slice().reverse().find(i => i.bets.length > 0)?.bets.slice();
                const bets = []
                if (mostRecentBets?.length > 0) {
                    mostRecentBets.forEach(i => {
                        bets.push({
                            betType: i.betType,
                            betChoice: i.betChoice,
                            amount: Math.pow(2, Math.max(0, lastLosingGameIndex))
                        })
                    })
                }
                return bets
            }
            case Strategy.flat: {
                const mostRecentBets = games.slice().reverse().find(i => i.bets.length > 0)?.bets.slice();
                const bets = []
                if (mostRecentBets?.length > 0) {
                    mostRecentBets.forEach(i => {
                        bets.push({
                            betType: i.betType,
                            betChoice: i.betChoice,
                            amount: i.amount
                        })
                    })
                }
                return bets;
            }
            case undefined:
                return [];
            default:
                throw new Error("No strategy behavior defined");
        }
    }

    const getGamePayoutSummary = (games: Game[]) => {
        function longestStreak(condition) {
            let longestStreak = 0;
            let count = 0;
            games.forEach(i => {
                if (condition(i)) {
                    count++;
                }
                if (count > longestStreak) {
                    longestStreak = count;
                }
                if (!condition(i)) {
                    count = 0;
                }

            })
            return longestStreak;
        }

        const summaries = games.filter(i => i.result !== undefined).map(i => {
            return {
                id: i.id,
                result: i.result,
                totalBetAmount: i.bets.map(i => i.amount).reduce((a, b) => a + b, 0),
                totalPayout: getTotalPayout(i),
                runningBalance: undefined,
                runningExpectedReturns: undefined,
                winningStreak: longestStreak((game: Game) => getTotalPayout(game) > 0),
                losingStreak: longestStreak((game: Game) => getTotalPayout(game) === 0),
                expectedReturns: getExpectedReturn(i)
            }
        })
        summaries.forEach(i => {
            i.runningBalance = summaries
                .filter(s => s.id <= i.id)
                .map(s => s.totalPayout - s.totalBetAmount)
                .reduce((a, b) => a + b, 0)
            i.runningExpectedReturns = summaries
                .filter(s => s.id <= i.id)
                .map(s => s.expectedReturns)
                .reduce((a, b) => a + b, 0)
        })
        return summaries
    }


    const getReversedCompletedGames = (games) => {
        const reversedGames = games.slice().reverse()
        return reversedGames.filter(i => i.result !== undefined)
    }
    const getCompletedGames = (games) => {
        return games?.filter(i => i.result !== undefined)
    }
    const reset = () => {
        setGames([{bets: [], id: games.length, result: undefined}])
        setPlayer(playerInitialState)
        setTable(tableInitialState)
    }
    const clearCurrentBets = () => {
        const tempNewGames = [...games]
        tempNewGames.filter(i=>i.result === undefined).forEach(i=>i.bets = [])
        setGames(tempNewGames)
    }

    return (<GameStateContext.Provider value={{
        games, setGames,
        player, setPlayer,
        table, setTable,
        getPayoutOdds, getDescription, getPayoutForBet, getTotalPayout,
        getCurrentGame, addGame, processGame, addBet, getActualOdds, getHouseEdge,
        getExpectedReturn, getGamePayoutSummary, getReversedCompletedGames,
        getProfitForGame, getCompletedGames, reset, clearCurrentBets
    }}>
        {props.children}
    </GameStateContext.Provider>)
}

export const useGameStateContext = () => {
    const gameStateContext = useContext(GameStateContext)
    return gameStateContext;
}