// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagline {
    font-size: 2rem;
    font-weight: 900;
}

.statements {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.4rem;
}

.statement {
    padding: 0.4rem;
    border-radius: 10px;
}

.statement:hover{
    color: var(--logoShade1);
}

.statement.false {
    background: var(--false-statement-background);
    border: 4px var(--false-statement-background-border) solid;
}

.statement.true {
    background: var(--true-statement-background);
    border: 4px var(--true-statement-background-border) solid;
}

.buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.4rem;
}

.button {
    background: var(--button-background);
    border: 4px var(--button-background-border) solid;
    padding: 0.4rem;
    border-radius: 10px;
    width: 20rem;
}

.button-small {
    background: var(--button-background);
    border: 4px var(--button-background-border) solid;
    padding: 0.1rem;
    border-radius: 4px;
    display:inline-block;
}

.button:hover{
    color: var(--logoShade1);
}

.button-small:hover{
    color: var(--logoShade1);
}

.button-header {
    font-size: 2rem;
}

.button-header-small {
    font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/home/home.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,6CAA6C;IAC7C,0DAA0D;AAC9D;;AAEA;IACI,4CAA4C;IAC5C,yDAAyD;AAC7D;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,iDAAiD;IACjD,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,iDAAiD;IACjD,eAAe;IACf,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".tagline {\n    font-size: 2rem;\n    font-weight: 900;\n}\n\n.statements {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 0.4rem;\n}\n\n.statement {\n    padding: 0.4rem;\n    border-radius: 10px;\n}\n\n.statement:hover{\n    color: var(--logoShade1);\n}\n\n.statement.false {\n    background: var(--false-statement-background);\n    border: 4px var(--false-statement-background-border) solid;\n}\n\n.statement.true {\n    background: var(--true-statement-background);\n    border: 4px var(--true-statement-background-border) solid;\n}\n\n.buttons {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 0.4rem;\n}\n\n.button {\n    background: var(--button-background);\n    border: 4px var(--button-background-border) solid;\n    padding: 0.4rem;\n    border-radius: 10px;\n    width: 20rem;\n}\n\n.button-small {\n    background: var(--button-background);\n    border: 4px var(--button-background-border) solid;\n    padding: 0.1rem;\n    border-radius: 4px;\n    display:inline-block;\n}\n\n.button:hover{\n    color: var(--logoShade1);\n}\n\n.button-small:hover{\n    color: var(--logoShade1);\n}\n\n.button-header {\n    font-size: 2rem;\n}\n\n.button-header-small {\n    font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
