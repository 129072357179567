import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter,
    createBrowserRouter, Route,
    RouterProvider, Routes,
} from "react-router-dom";
import {Home} from "./components/home/Home";
import { Header } from './components/header/Header';
import Roulette from "./roulette/components/Roulette";
import {ErrorBoundary} from "react-error-boundary";
import Blackjack from './roulette/components/Blackjack';
import Baccarat from "./roulette/components/Baccarat";
import About from './roulette/components/About';
import Articles from "./roulette/components/Articles";
import Article from './roulette/components/Article';
import {Footer} from "./components/header/Footer";

function App() {

    return (
        // @ts-ignore
        <BrowserRouter>
            <ErrorBoundary fallback={<>"Error"</>}>
                <div className="App">
                    <Header></Header>
                    <div className="content">
                        <Routes>
                            <Route path="/" element={<Home/>} />
                            <Route path="/roulette" element={<Roulette/>} />
                            <Route path="/blackjack" element={<Blackjack/>} />
                            <Route path="/baccarat" element={<Baccarat/>} />
                            <Route path="/about" element={<About/>} />
                            <Route path="/article/:id" element={<Article/>} />
                            <Route path="/articles" element={<Articles/>} />
                        </Routes>
                    </div>
                    <Footer></Footer>
                </div>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
