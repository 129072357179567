import "./header.css"
import {BrowserRouter, Link} from "react-router-dom";

export function Header() {
    // throw new Error('I crashed!');
    return (<div className="header">
        <Link to="/">
            <div className="logo">
                <img src="/logo.png" height="70"/>
                <span style={{color: "var(--logoShade1)"}}>Just</span>
                <span style={{color: "var(--logoShade2)"}}>Stop</span>
                <span style={{color: "var(--logoShade3)"}}>Gambling</span>
            </div>
        </Link>
        <div className="menu">
            <Link to="/"><div className="menu-item">home</div></Link>
            {/*<div className="menu-item">uncomfortable truths</div>*/}
            <Link to="/articles"><div className="menu-item">articles</div></Link>
            <Link to="/roulette"><div className="menu-item">roulette</div></Link>
            <Link to="/blackjack"><div className="menu-item">blackjack</div></Link>
            <Link to="/baccarat"><div className="menu-item">baccarat</div></Link>
            <Link to="/about"><div className="menu-item">about</div></Link>
        </div>
    </div>)
}