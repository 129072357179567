import {Link} from "react-router-dom";

export default function PlayRouletteButton() {
    return <Link className="font-light" to="/roulette">
        <div className="button font-light text-align-center">
            <div className="button-header">Play Roulette</div>
            <div className="button-body">Test what you have learnt in a safe environment with play-money
            </div>
        </div>
    </Link>
}