import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useGameStateContext} from "../utils/GameContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.defaults.elements.line.borderWidth = 2

export function Graph() {
    const {games, getGamePayoutSummary, getExpectedReturn, getCompletedGames} = useGameStateContext();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Bank Balance Graph',
            },
        },
    };
    let gamePayoutSummaries = getGamePayoutSummary(games);
    let maxFullDetailSize = 10;
    let dataLength = gamePayoutSummaries.length;
    const gamePayoutSummariesSliced = gamePayoutSummaries.slice().filter((value, index, Arr) =>
        // dataLength <= maxFullDetailSize ||
        //     (index == 0 || index >= dataLength - 10)
        //     ||
        // (Math.sign(gamePayoutSummaries[index].runningBalance - (gamePayoutSummaries[index-1].runningBalance)) !=
        // Math.sign(gamePayoutSummaries[index+1].runningBalance - (gamePayoutSummaries[index].runningBalance)))
        //     ||
        //     (index % (dataLength / maxFullDetailSize) == 0)
        true
    );
    let labels = getCompletedGames(games);
    const labelsSliced = labels.slice().filter((value, index, Arr) =>
        // gamePayoutSummariesSliced.filter(i=>i.id == index).length > 0
        true
    );
    const data = {
        labels: labelsSliced?.map(i=>i.id),
        datasets: [
            {
                label: 'Bank Balance',
                data: gamePayoutSummariesSliced?.map(i=>i.runningBalance),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Expected Bank Balance',
                data: gamePayoutSummariesSliced?.map(i=>i.runningExpectedReturns),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return <Line options={options} data={data} />;
}