import {Bet, BetType, GameStateContextProvider, Strategy, useGameStateContext} from "../utils/GameContext";
import "./roulette.css"
import {redNumbers} from "../utils/Utils";
import {useEffect, useState} from "react";
import {Graph} from "./Graph";


export default function PlayRoulette() {
    const {
        games, player, setPlayer, getExpectedReturn, getReversedCompletedGames, addBet,
        addGame, processGame, reset, clearCurrentBets
    } = useGameStateContext();
    const [betSize, setBetSize] = useState(1);
    const [multiSpinUi, setMultiSpinUi] = useState(false);
    const [multiSpinNumber, setMultiSpinNumber] = useState(10);
    const [runSimulation, setRunSimulation] = useState(false);
    const [showGraph, setShowGraph] = useState(true);
    const [strategy, setStrategy] = useState(undefined);
    const [strategyToUseInProcessGame, setStrategyToUseInProcessGame] = useState(undefined);
    const [warning, setWarning] = useState(undefined);
    const reversedCompletedGames = getReversedCompletedGames(games);
    const lastGame = reversedCompletedGames.length > 0 ? reversedCompletedGames[0] : undefined;
    const lastResult = reversedCompletedGames.length > 0 ? reversedCompletedGames[0].result : undefined
    const currentBets = games.length > 0 ? games[games.length - 1].bets?.map(i => i.amount).reduce((a, b) => a + b, 0) : 0;

    useEffect(() => {
        reset()
        document.title = "JustStopGambling.org | Roulette"
    }, [])

    useEffect(() => {
        console.log(runSimulation);
        if (runSimulation) {
            processGame(strategyToUseInProcessGame);
        } else {
            addGame(1)
        }
        return setRunSimulation(false)
    }, [runSimulation])

    function placeBet(betType: BetType, betValue: number) {
        const bet: Bet = {amount: betSize, betChoice: betValue, betType: betType};
        addBet([bet]);
    }

    function changeStrategy(id: number) {
        console.log("id: " + id)
        //wtf have i done
        switch(id) {
            case 0:
                setWarning("All bets begin simulation at size of $1 for Martingale strategies")
                setStrategy(Strategy.martingale);
                break;
            case 1:
                setWarning("All bets begin simulation at size of $1 for Martingale strategies")
                setStrategy(Strategy.reverseMartingale);
                break;
            case 2:
                setStrategy(Strategy.flat);
                break;
            default:
                throw new Error()
        }
    }

    useEffect(() => {

        changeStrategy(2)
    }, [])

    return <>
        <div className="roulette-container font-light">
            <div style={{
                display: "flex",
                gap: "1rem",
                margin: "1rem"
            }}>
                <ValueWithLabel edit={false} label={"Balance"}
                                value={"$" + (player.balance.toFixed(0))}></ValueWithLabel>
                <ValueWithLabel edit={false} label={"Bets"} value={"$" + currentBets}></ValueWithLabel>
                <ValueWithLabel edit={false} label={"Expected Returns"}
                                value={"$" + getExpectedReturn().toFixed(2)}></ValueWithLabel>
            </div>
            <div className="roulette-board-container">
                <div className="roulette-board">
                    <RouletteNumber onClick={placeBet} number={0}/>
                    <div className="roulette-board-numbers">
                        <div className="roulette-number-row">
                            <RouletteNumber onClick={placeBet} number={3}/>
                            <RouletteNumber onClick={placeBet} number={6}/>
                            <RouletteNumber onClick={placeBet} number={9}/>
                            <RouletteNumber onClick={placeBet} number={12}/>
                            <RouletteNumber onClick={placeBet} number={15}/>
                            <RouletteNumber onClick={placeBet} number={18}/>
                            <RouletteNumber onClick={placeBet} number={21}/>
                            <RouletteNumber onClick={placeBet} number={24}/>
                            <RouletteNumber onClick={placeBet} number={27}/>
                            <RouletteNumber onClick={placeBet} number={30}/>
                            <RouletteNumber onClick={placeBet} number={33}/>
                            <RouletteNumber onClick={placeBet} number={36}/>
                        </div>
                        <div className="roulette-number-row">
                            <RouletteNumber onClick={placeBet} number={2}/>
                            <RouletteNumber onClick={placeBet} number={5}/>
                            <RouletteNumber onClick={placeBet} number={8}/>
                            <RouletteNumber onClick={placeBet} number={11}/>
                            <RouletteNumber onClick={placeBet} number={14}/>
                            <RouletteNumber onClick={placeBet} number={17}/>
                            <RouletteNumber onClick={placeBet} number={20}/>
                            <RouletteNumber onClick={placeBet} number={23}/>
                            <RouletteNumber onClick={placeBet} number={26}/>
                            <RouletteNumber onClick={placeBet} number={29}/>
                            <RouletteNumber onClick={placeBet} number={32}/>
                            <RouletteNumber onClick={placeBet} number={35}/>
                        </div>
                        <div className="roulette-number-row">
                            <RouletteNumber onClick={placeBet} number={1}/>
                            <RouletteNumber onClick={placeBet} number={4}/>
                            <RouletteNumber onClick={placeBet} number={7}/>
                            <RouletteNumber onClick={placeBet} number={10}/>
                            <RouletteNumber onClick={placeBet} number={13}/>
                            <RouletteNumber onClick={placeBet} number={16}/>
                            <RouletteNumber onClick={placeBet} number={19}/>
                            <RouletteNumber onClick={placeBet} number={22}/>
                            <RouletteNumber onClick={placeBet} number={25}/>
                            <RouletteNumber onClick={placeBet} number={28}/>
                            <RouletteNumber onClick={placeBet} number={31}/>
                            <RouletteNumber onClick={placeBet} number={34}/>
                        </div>
                    </div>
                </div>
                <div className="roulette-board-bottom-board">
                    <RouletteBet onClick={placeBet} betName="1 to 18" betType={BetType.Half} betValue={0}/>
                    <RouletteBet onClick={placeBet} betName="Even" betType={BetType.EvenOdd} betValue={0}/>
                    <RouletteBet onClick={placeBet} betName="Red" betType={BetType.RedBlack} betValue={0}/>
                    <RouletteBet onClick={placeBet} betName="Black" betType={BetType.RedBlack} betValue={1}/>
                    <RouletteBet onClick={placeBet} betName="Odd" betType={BetType.EvenOdd} betValue={1}/>
                    <RouletteBet onClick={placeBet} betName="19 to 36" betType={BetType.Half} betValue={1}/>
                </div>
            </div>
            <div style={{
                display: "flex",
                gap: "1rem",
                margin: "1rem"
            }}>
                <button onClick={() => {
                    clearCurrentBets()
                }}>Clear Bets
                </button>
                <button onClick={() => setBetSize(1)}>Reset</button>
                <button onClick={() => setBetSize(prevState => Math.max(1, Math.round(prevState / 2)))}>/2</button>
                <button onClick={() => setBetSize(prevState => Math.max(1, prevState - 1))}>-1</button>
                <ValueWithLabel label={"Bet Size"} value={"$" + betSize}></ValueWithLabel>
                <button onClick={() => setBetSize(prevState => prevState + 1)}>+1</button>
                <button onClick={() => setBetSize(prevState => prevState * 2)}>x2</button>
            </div>
            <div style={{
                display: "flex",
                gap: "1rem",
                margin: "1rem"
            }}>
                <button onClick={() => {
                    reset()
                }}>Reset Game
                </button>
                <button onClick={() => {
                    setStrategyToUseInProcessGame(undefined)
                    setRunSimulation(true)
                }}>Single Spin
                </button>
                or
                <button onClick={() => {
                    setMultiSpinUi(!multiSpinUi)
                }}>Multiple Spins
                </button>
            </div>
            {multiSpinUi && <div>
                <p>Multiple Spin Simulations</p>
                <div className="flex">
                    <div>Simulate
                        <select onChange={(i) => {
                            setMultiSpinNumber(i.target.value as unknown as number)
                        }}>
                            <option value={10}>10 Spins</option>
                            <option value={100}>100 Spins</option>
                            <option value={1000}>1,000 Spins</option>
                            {/*<option value={10000}>10,000 Spins</option>*/}
                        </select>
                    </div>
                    <div>Betting strategy
                        <select onChange={(i) => {
                            changeStrategy(+i.target.value)
                        }}>
                            <option value={Strategy.flat}>Flat</option>
                            <option value={Strategy.martingale}>Martingale</option>
                            <option value={Strategy.reverseMartingale}>Reverse Martingale</option>
                        </select>
                    </div>
                </div>
                <div className={"warning"}>{warning}</div>
                <button onClick={() => {
                    addGame(multiSpinNumber-1)
                    setStrategyToUseInProcessGame(strategy)
                    setRunSimulation(true)
                }}>Simulate
                </button>
            </div>}
            {reversedCompletedGames.length > 0 && <p>Last spin</p>}
            {lastResult !== undefined && <RouletteResult game={lastGame}/>}
            {reversedCompletedGames.length > 0 && <p>Last 40 spins</p>}
            <div style={{
                display: "flex",
                justifyContent: "start",
                width: "90%",
                gap: "1.1rem",
                margin: "1rem",
                flexWrap: "wrap",
            }}>
                {reversedCompletedGames.slice(0, 40).map(i => {
                    return <RouletteResult key={i.id} game={i}/>
                })}
            </div>
        </div>
        <button onClick={() => {
            setShowGraph(!showGraph)
        }}>Toggle Graph
        </button>
        {showGraph && <Graph/>}
    </>
}

function ValueWithLabel({edit = true, label, value}) {
    return (<div className="vwl-container">
        <label htmlFor={label} className="vwl-label">{label}</label>
        <div id={label} className="vwl-value">{value}</div>
    </div>);
}

function RouletteNumber({onClick, number}) {
    const {games, getCurrentGame, getReversedCompletedGames} = useGameStateContext();
    const bet = getCurrentGame()?.bets.filter(b => b.betType === BetType.Single && b.betChoice === number);
    const lastResult = getReversedCompletedGames(games)?.length > 0 ? getReversedCompletedGames(games)[0].result : undefined

    return <div onClick={() => onClick(BetType.Single, number)}
                className={"roulette-number" + " " + (redNumbers.includes(number) ? "red" : "black") + " " +
                    (lastResult === number ? "highlighted" : "")}>{number}
        <Chips number={bet?.length > 0 && bet[0]?.amount}/>
    </div>;
}

function RouletteBet({onClick, betName, betType, betValue}) {
    const {getCurrentGame} = useGameStateContext();
    const bet = getCurrentGame()?.bets.filter(b => b.betType === betType && b.betChoice === betValue);
    return <div onClick={() => onClick(betType, betValue)} className={"roulette-bet"}>{betName}
        <Chips number={bet?.length > 0 && bet[0]?.amount}/>
    </div>;
}

function Chips({number = 0}) {
    return (<div className={"chip-text"}>{number > 0 && "$" + number}</div>);
}

function RouletteResult({game}) {
    const {getProfitForGame} = useGameStateContext();
    const profit = getProfitForGame(game);

    return <div className={"roulette-number" + " " + (redNumbers.includes(game.result) ? "red" : "black")}>{game.result}
        <Profit number={profit}/>
    </div>;
}

function Profit({number = 0}) {
    return (<div className={"chip-text"}>{number != 0 && (number > 0 ? "+$" + number : "-$" + Math.abs(number))}</div>);
}

