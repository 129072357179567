// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width:100%;
    display:flex;
    flex-wrap: wrap;
    background: var(--header-background);
    justify-content: center;
    align-items: center;
}

.header .logo {
    font-weight: 900;
    font-size: 2rem;
    padding:0.5rem;
    margin-left: 0.5rem;
    display:flex;
    justify-content: center;
    align-items: center;
}

.menu {
    display:flex;
    flex-wrap: wrap;
    margin-left:1rem;
    min-height: auto;
    justify-content: center;
    align-items: center;
}

.menu-item {
    padding:0.3rem;
    font-weight: 500;
    font-size: 1.2rem;
    border-left: var(--link-hover) 1px solid;
}

.menu-item:last-child {
    padding:0.3rem;
    font-size: 1.2rem;
    border-right: var(--link-hover) 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,eAAe;IACf,oCAAoC;IACpC,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,wCAAwC;AAC5C;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,yCAAyC;AAC7C","sourcesContent":[".header {\n    width:100%;\n    display:flex;\n    flex-wrap: wrap;\n    background: var(--header-background);\n    justify-content: center;\n    align-items: center;\n}\n\n.header .logo {\n    font-weight: 900;\n    font-size: 2rem;\n    padding:0.5rem;\n    margin-left: 0.5rem;\n    display:flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.menu {\n    display:flex;\n    flex-wrap: wrap;\n    margin-left:1rem;\n    min-height: auto;\n    justify-content: center;\n    align-items: center;\n}\n\n.menu-item {\n    padding:0.3rem;\n    font-weight: 500;\n    font-size: 1.2rem;\n    border-left: var(--link-hover) 1px solid;\n}\n\n.menu-item:last-child {\n    padding:0.3rem;\n    font-size: 1.2rem;\n    border-right: var(--link-hover) 1px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
