import {GameStateContextProvider} from "../utils/GameContext";
import PlayRoulette from "./PlayRoulette";
import {useEffect} from "react";

export default function About() {
    useEffect(() => {
        document.title = "JustStopGambling.org | About"
    }, [])
    return <>
        <h1>About<hr/></h1>
        <p>
            This website is a small project in the fight against the legalized global gambling industries worldwide that prey on
            myths and misunderstandings of statistics and mathematics.
        </p>
        <p>
            I noticed there was no such website online to play casino games with play money without being bombarded with adverts
            to encourage you to play with real money, even on websites that claimed to be exposing gambling systems. This website
            will never use advertising to encourage gambling and is purely for non-profit.
        </p>
    </>
}