import {GameStateContextProvider} from "../utils/GameContext";
import PlayRoulette from "./PlayRoulette";

export default function Roulette() {
    return <>
        <h1>Roulette<hr/></h1>
        <p>Play Roulette safely with play money.
            The key takeaway from this should be that it is impossible to play this game badly or well.
            No matter what you do, your balance will edge towards your expected loses over time, you cannot do anything
            to change this, although it may be difficult to accept.
        </p>
        <p>
            Try to play badly, eventually you will find its impossible to play well or badly at roulette.
        </p>
        {/*<div style={{background: "black", width:"1000px"}}>test</div>*/}
        <GameStateContextProvider>
            <PlayRoulette></PlayRoulette>
        </GameStateContextProvider>
    </>
}