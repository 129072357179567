import {useParams} from "react-router-dom";
import PlayRouletteButton from "../../components/PlayRouletteButton";
import {useEffect} from "react";

const articles = require("./articles.json")

export default function Articles() {
    let {id} = useParams();
    const article = articles.filter(i=>i.id == id)[0]
    useEffect(() => {
        document.title = "JustStopGambling.org | " + article.title
    }, [])
    return <>
        <div className={"text-align-left"}>
            <h1>{article.title}<hr/></h1>
            {
                <p>
                    <div dangerouslySetInnerHTML={ { __html: article.content } }></div>
                </p>
            }
            <PlayRouletteButton/>
        </div>
    </>
}