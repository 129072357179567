import {GameStateContextProvider} from "../utils/GameContext";
import PlayRoulette from "./PlayRoulette";
import {useEffect} from "react";

export default function Baccarat() {
    useEffect(() => {
        document.title = "JustStopGambling.org | Baccarat"
    }, [])
    return <>
        <h1>Baccarat<hr/></h1>
        <p><b>Coming Soon</b> - Play Baccarat safely with play money.</p>
        <p>
            The key takeaway from this should be that it is impossible to play this game badly or well.
            No matter what you do, your balance will edge towards your expected loses over time, you cannot do anything
            to change this, although it may be difficult to accept.
        </p>
        <p>
            Try to play badly, eventually you will find its impossible to play well or badly at baccarat.
        </p>
        <GameStateContextProvider>
            {/*<PlayRoulette></PlayRoulette>*/}
        </GameStateContextProvider>
    </>
}