import {GameStateContextProvider} from "../utils/GameContext";
import PlayRoulette from "./PlayRoulette";
import {useEffect} from "react";

export default function Roulette() {
    useEffect(() => {
        document.title = "JustStopGambling.org | Blackjack"
    }, [])
    return <>
        <h1>Blackjack<hr/></h1>
        <p><b>Coming Soon</b> - Play Blackjack safely with play money.</p>
        <p>
            The key issue with Blackjack is there a clear optimal way to play the game for every situation, and this is still a house edge.
            Any deviation from this strategy will lose you even <b>more</b> money.
        </p>
        <p>
            The only way to win is card counting. Casino's have taken many measures against card counting,
            however advanced card counting is still possible but you will quickly banned from any casino that is able
            to notice you counting cards.
        </p>
        <GameStateContextProvider>
            {/*<PlayRoulette></PlayRoulette>*/}
        </GameStateContextProvider>
    </>
}