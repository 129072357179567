import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import SmallButton from "../../components/SmallButton";
import "./articles.css"

const articles = require("./articles.json")

export default function Articles() {
    useEffect(() => {
        document.title = "JustStopGambling.org | Articles"
    }, [])
    return <>
        <h1>Articles about Gambling<hr/></h1>
        <div className={"text-align-left"}>
        {
            articles.map(i=>
                <>
                    <Link to={"/article/" + i.id}>
                        <h2 className={"article-header"}>{i.title}</h2>
                    </Link>
                        <p className={"article-subheading"}>{i.subheading}</p>
                        <SmallButton title="Continue Reading >" to={"/article/" + i.id}></SmallButton>
                        {/*<hr/>*/}
                </>
            )
        }
        </div>
    </>
}