// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-subheading {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

h2.article-header {
    margin-top: 2rem;
    margin-bottom: 0rem;
}
`, "",{"version":3,"sources":["webpack://./src/roulette/components/articles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".article-subheading {\n    margin-top: 0.2rem;\n    margin-bottom: 0.2rem;\n}\n\nh2.article-header {\n    margin-top: 2rem;\n    margin-bottom: 0rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
